@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
body,h1,h2,h3,h4,h5,h6,p,div,a,span,ul,li{
  font-family: 'Roboto', sans-serif !important;
}
body{
  letter-spacing: 0.3px;
}
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.mainLayoutCallAI .ant-layout-content:has(.playListCardMainWrpr ){
  height: 100%;
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nmusercircle{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 35px;
  height: 35px;
  border-radius: 100px;
  background: rgb(45, 52, 70);
  color: white;
}

.loading .ant-spin {
  color: #3e79f7;
}
.chAiFlexCenter{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.flex{
  display: flex !important;
}
.alignCenter{
  align-items: center !important;
}
.justifyCenter{
  justify-content: center !important;
}

.barChartIcon {
  background-image: url(assets/svg/bar_chart.svg);
  height: 9px;
  width: 9px;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.tableSearchMain {
  position: relative;
}
.tableSearchMain  .ant-input-wrapper .ant-btn,
.tableSearchMain  .ant-input-wrapper .ant-input-group-addon{
    background-color:unset !important;
    transition: unset !important;
}
.tableSearchMain  .ant-input-wrapper input::placeholder{
  color: #989898;
}
.tableSearchMain  .ant-input-wrapper input{
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: unset !important;
  background-color: unset !important;
  transition: unset !important;
}
.tableSearchMain  .ant-input-wrapper input:is(:focus,:active){
  box-shadow: unset !important;;
}
.callAnalyticsTblCard{
    border-radius: 6px !important;  
    margin-bottom: 0 !important;
}
.tableSearchMain button,
.tableSearchMain button:is(:focus, :hover, :focus-visible) {
  position: absolute;
  background: white;
  top: 6px;
  border: unset;
  padding: 0;
  width: auto !important;
  left: -20px;
  height: auto !important;
  z-index: 2 !important;
  cursor: unset !important;
  color: rgba(69, 85, 96, 0.45) !important;
  box-shadow: unset !important;
  outline: unset !important;
}

.tableSearchMain input {
  border: unset !important;
  border-radius: unset !important;
  padding: 4px 20px 6px 4px !important;
}

.filterOpiton,
.downloadOption {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
}

.topAllOptions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filterOpiton {
  background-color: #26A69A;
  margin-left: 10px !important;
}
.downloadOption {
  background-color: #2685EE;
}
.text-lable {
  margin-top: 20px;
}
.downloadIcon,
.filterIcon {
  height: 18px;
  width: 18px;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.downloadIcon {
  background-image: url(assets/svg/file_download.svg);
}
.filterIcon {
  background-image: url(assets/svg/filter.svg);
}
.pageHeader {
  font-weight: 500;
  color: rgba(0,0,0,.85) !important;
  margin-bottom: 0 !important;
  line-height: normal;
  font-size: 1.5em !important;
}
.logo {
  cursor: pointer;
}
.userProfileDiv{
  display: flex;
  gap: 8px;
  position: absolute;
  bottom: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.userDetails span{
  font-size: 13px;
  font-weight: 600;
  color: #000;
}
.userDetails p{
  color: #aba5a5;
  font-size: 11px;
  margin-bottom: 0 !important;
  line-height: normal;
}
.arrow_left_icon {
  height: 18px;
  width: 18px;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.arrow_left_icon {    
  background-image: url('assets/svg/back_icon.svg');
  cursor: pointer;
}
.userProfileDiv .arrow_left_icon{
  transform: rotate(180deg) !important;
}
.logOutBtn:is(:hover,:focus,:active),
.logOutBtn{
  background-color: unset !important;
  box-shadow: unset !important;
  border: unset !important;
  padding: 0 !important;
  margin-left: 22px;
}

.PrivateOptdiv,
.TeamOptdiv{
  display: flex;
  align-items: center;
  gap: 6px;
  color:#455560 !important;;
}
.privateDrpIcon,
.TeamDrpIcon{
    display: block;
    height: 24px;
    width: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.TeamDrpIcon{
    background-image: url(assets/svg/teams_icon.svg);
}
.privateDrpIcon{
    background-image: url(assets/svg//private_icon.svg);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected:not(.ant-select-item-option-disabled):is(:hover,:focus,:active){
  background-color: rgb(248 228 226) !important;
  color: #455560  !important;
}
.ant-select-item:is(:hover,:focus,:active){
  background-color: #fff3f2 !important;
}
.customBreadCumb .anticon{  
  font-size: 12px !important;
}
.customBreadCumb .anticon,
.customBreadCumb a,.callCnvText{
  color: #000 !important;
  font-size: 14px;
}
.customBreadCumb a{
  font-weight: 600;
}

/* confirm popup css start */
.ant-modal-confirm-btns{
  margin-top: 30px !important;
}
.ant-modal-confirm-btns .ant-btn{
  border-radius: 4px!important;
}
.ant-modal-confirm-btns .ant-btn-primary{
  background-color: #E17749 !important;
}
.ant-modal-confirm-btns .ant-btn-primary:hover{
  background-color: #d65117 !important;
  border-color: #d65117 !important;
}
.ant-modal-confirm-btns .ant-btn-default:hover{
  border-color: #E17749 !important;
  color:#E17749 !important;
}
.ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon {
  color: #E17749 !important;
}
/* confirm popup css end */
.arrowProfIcon{
  margin-left: 4px;
}
.ant-dropdown-trigger:hover .arrowProfIcon{
  color: #455560 !important;
}
.spiner_div{
  height:100vh;
  width:100%;
  display: flex;
  align-items: center;
  justify-content:center;
  position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
/* menu css start*/
.navHamburgerCtntr{
  padding-left: 6px !important;
}
.navHamburgerCtntr:hover span{
  color: #455560 !important;
}
@media (max-width:991.98px) {
  .navHamburgerCtntr{
    padding-left: 15px !important;
  } 
}
.ant-menu-item-selected{
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: #464646 !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title{
  color: #464646 !important;
}
.ant-menu-item{
  border-radius: unset !important;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s !important;
  margin: 0 !important;
}
.ant-menu-item:hover{
  background-color: rgb(52, 73, 94) !important;
  color: #fff !important;
}
.sideBarMenuCtntr > li.ant-menu-item:first-child{
  background-color: rgba(0, 0, 0, 0.05) !important;
  margin-bottom: 5px !important;
}
.sideBarMenuCtntr .ant-menu-item:hover .dashboardIcon,
.sideBarMenuCtntr .ant-menu-item:hover .myPlayListIcon{
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(281deg) brightness(103%) contrast(102%) !important;
}
.sideBarMenuCtntr .ant-menu-item:hover span:nth-of-type(2){
  color: #fff !important;
}
.sideBarMenuCtntr{
  padding-top: 3px !important;
}
.ant-menu-submenu-popup .ant-menu{
  border-radius: 4px !important;
}
.navHamburgerIcon{
  background-image: url(assets/svg/menu.svg);
  background-size: contain;
  width: 18px;
  height: 21px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
/* menu css end*/
.ant-tooltip .ant-tooltip-arrow:before,
.ant-tooltip-inner,
.blackPopover .ant-popover-inner,
.blackPopover .ant-popover-arrow::before {
  background-color: rgba(0, 0, 0, 0.75) !important;
  border-radius: 4px !important;
  color: #fff !important;
}
.blackPopover .ant-popover-inner-content{
  color: #fff !important;
}
.callAiToast.ant-notification-notice{
  width: max-content !important;
  padding: 18px 20px !important;
}
.callAiToast.ant-notification-notice .ant-notification-notice-message{
  margin-bottom: 0 !important;
}
.callAiToast.ant-notification-notice .ant-notification-notice-description{
  display: none !important;
}
.topicsInfoIcon{
  color:#484747 !important;
  margin-left:5px;
}

.callLogFilterPopup {
  max-width: 450px !important;
}
.callLogFilterPopup .ant-modal-content{
  padding:0px !important;
  border-radius: 5px !important;
}
.callLogFilterPopup .ant-modal-body{
  min-height: 503px;
}
.callLogFilterPopup .ant-modal-body{
  padding: 24px;
}
.callLogFilterPopup .ant-modal-header{
  padding: 16px 24px;
  background: rgba(221, 221, 221, 0.19);
  border-radius: 5px 5px 0 0 !important;
  border-bottom:1px solid #e8e8e8 !important;
  margin-bottom: 0px !important;
}
.callLogFilterPopup .ant-modal-title{
  text-align:center;
}